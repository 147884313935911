import React, { useEffect, useState } from 'react';
import { Button, Dialog, Grid, Box, TextField } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from '../ConfirmDeletionModal/styles.module.scss';
import '../ConfirmDeletionModal/styles.module.scss';
import { withDimensions } from '../../../common/HOC';
import { isFunc } from '../../../../utils/functions';
import PositiveIntegerFormat from '../PositiveIntegerFormat';


const ProvideElapsedTimeModal = ({
                            open,
                            setOpen,
                            title,
                            description,
                            placeholderMinutes = '',
                            placeholderHours = '',
                            closeOnSubmit = false,
                            allowedEmpty = false,
                            yesLabel,
                            noLabel,
                            parameters
}) => {
  const dimensions = useSelector(state => state.dimensions);

  const [currentHours, setCurrentHours] = useState('');
  const [currentMinutes, setCurrentMinutes] = useState('');

  useEffect(() => {
    setCurrentHours(parameters.initialHours ?? '');
    setCurrentMinutes(parameters.initialMinutes ?? '');
  }, [parameters, open]);//eslint-disable-line

  return (
    <>
      <Dialog
        open={open}
        className={'ConfirmDeletionDialog'}
        onClose={() => { setCurrentHours(''); setCurrentMinutes(''); }}
        fullScreen={dimensions.isScreenLessSM}
      >
        <MuiDialogTitle disableTypography>
          <Box className={`${styles.modalTitle} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>{title}</Box>
        </MuiDialogTitle>
        <MuiDialogContent>
          {!!description && (
            <Box className={styles.text}>
              <p>{description}</p>
            </Box>
          )}
          <Grid container spacing={4} justifyContent={'flex-start'}>
            <Grid item xs={6} sm={6}>
              <Box className={styles.label}>Hours</Box>
              <TextField
                placeholder={placeholderHours}
                value={currentHours ?? ''}
                onChange={(e) => {
                  setCurrentHours(e.target.value);
                }}
                fullWidth
                variant="outlined"
                name="hoursValue"
                InputProps={{
                  inputComponent: PositiveIntegerFormat,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box className={styles.label}>Minutes</Box>
              <TextField
                placeholder={placeholderMinutes}
                value={currentMinutes ?? ''}
                onChange={(e) => {
                  setCurrentMinutes(e.target.value);
                }}
                fullWidth
                variant="outlined"
                name="minutesValue"
                InputProps={{
                  inputComponent: PositiveIntegerFormat,
                }}
              />
            </Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Grid container justifyContent="space-between">
            <Button
              className={`${styles.actionButton} ${styles.secondary}`}
              onClick={() => { if (isFunc(parameters?.noAction)) parameters.noAction({ minutes: currentMinutes, hours: currentHours }); setOpen(false); }}
              color="secondary"
              variant="outlined"
              type="button"
              disableElevation
            >
              {noLabel ?? 'CANCEL'}
            </Button>
            <Button
              className={`${styles.actionButton}`}
              type="button"
              variant="contained"
              color="primary"
              disabled={!allowedEmpty && !currentHours?.trim?.()?.length && !currentMinutes?.trim?.()?.length}
              onClick={() => {
                if (isFunc(parameters?.yesAction)) parameters.yesAction({ minutes: currentMinutes, hours: currentHours });
                if (closeOnSubmit) setOpen(false);
              }}
            >
              {yesLabel ?? 'SUBMIT'}
            </Button>
          </Grid>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

ProvideElapsedTimeModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  parameters: PropTypes.object,
  description: PropTypes.string,
  placeholderMinutes: PropTypes.string,
  placeholderHours: PropTypes.string,
  closeOnSubmit: PropTypes.bool
};

export default withDimensions(ProvideElapsedTimeModal);

