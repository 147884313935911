import { MONTHLY } from '@src/vars';
import config from "@src/config";

const MAX_SPLIT_LOANS = config.MAX_SPLIT_LOANS ?? 4;

const defaultState = {
  referredUID: undefined,
  reference: undefined,
  id: undefined,
  forUser: undefined,
  journeyStage: undefined, //exploring|searching|found|urgent
  isSignedContract: undefined, //yes|no
  settlementDate: null, //yyyy-MM-dd
  isConveyancer: undefined, //yes|no
  isPreApproval: undefined, //yes|no
  isRefinance: undefined,
  numberOfLoans: undefined,
  lendProvider: null,
  amountPreApproved: null,
  remainingLoanBalance: Array(MAX_SPLIT_LOANS).fill(null),
  interestRate: Array(MAX_SPLIT_LOANS).fill(null),
  parVariable: Array(MAX_SPLIT_LOANS).fill('variable'), //variable|fixed
  parInterest: Array(MAX_SPLIT_LOANS).fill('principal + interest'), //'principal + interest'|'interest only'
  estimatedPrice: null,
  deposit: null,
  purposeToBuy: Array(MAX_SPLIT_LOANS).fill(null), //'to live in'|'to invest'
  isFirstHome: undefined, //yes|no
  estimation: undefined,//object with estimated repayments
  isEvaluated: false,
  isAdviceApplied: false,
  loanSummary: undefined,//object
  advice: undefined,//object
  preferredRateType: undefined, //Variable|Fixed
  preferredFixedRateTerm: undefined,
  preferredRepaymentType: undefined, //'Principal & Interest'|'Interest only',
  preferredLenderType: undefined, //All|Bank|Non-bank
  preferredBankType: undefined, //All|Major
  preferenceInterestRate: undefined, //VeryImportant|NiceToHave|NotImportant
  preferenceCashBack: undefined, //VeryImportant|NiceToHave|NotImportant
  dealFeatures: undefined,
  dealAttributes: undefined,
  selectedDealFeatures: undefined,
  foundDeals: undefined,
  dealsToCompare: [],
  chosenDeal: undefined,
  isChooseLater: false,
  personalisedDeals: undefined,
  firstName: null,
  middleName: null,
  lastName: null,
  email: null,
  address: undefined,
  propertyAddress: undefined,

  //estimatedPropertyId: null,
  propertyEstimation: undefined,
  //customPropertyEstimation: false,
  propertyState: '',//'ACT'|'NSW'|'NT'|'QLD'|'SA'|'TAS'|'VIC'|'WA'|'NOT SURE YET'
  propertyType: '',//'EstablishedHome'|'NewHome'|'VacantLand'|'NotSureYet'

  propertyAddressSignedContract: undefined,
  isAgree: false,
  registrationToken: undefined,
  mobile: null,
  code: null,
  verifiedMobile: undefined,
  invitation: undefined,
  customerInvite: undefined,
  remainingFixedTerm: Array(MAX_SPLIT_LOANS).fill(undefined),
  remainingFixedTermUnit: Array(MAX_SPLIT_LOANS).fill('years'), //months|years
  remainingLoanTerm: Array(MAX_SPLIT_LOANS).fill(undefined),
  remainingLoanTermUnit: Array(MAX_SPLIT_LOANS).fill('years'), //months|years
  inviteWithUserNeeds: false,
  propertyPurpose: null,
  propertyRentalIncome: null,
  propertyRentalIncomeUnit: MONTHLY,
  propertyOwnership: undefined,
  propertyOwnershipPercentage: null
};

export const actionTypes = {
  REFERRED_UID: 'REFERRED_UID',
  REFERENCE: 'REFERENCE',
  ID: 'ID',
  FOR_USER: 'FOR_USER',
  STATE: 'STATE',
  STATE_RESET: 'STATE_RESET',
  JOURNEY_STAGE: 'JOURNEY_STAGE',
  IS_SIGNED_CONTRACT: 'IS_SIGNED_CONTRACT',
  SETTLEMENT_DATE: 'SETTLEMENT_DATE',
  IS_CONVEYANCER: 'IS_CONVEYANCER',
  IS_PRE_APPROVAL: 'IS_PRE_APPROVAL',
  IS_REFINANCE: 'IS_REFINANCE',
  NUMBER_OF_LOANS: 'NUMBER_OF_LOANS',
  LEND_PROVIDER: 'LEND_PROVIDER',
  AMOUNT_PRE_APPROVED: 'AMOUNT_PRE_APPROVED',
  REMAINING_LOAN_BALANCE: 'REMAINING_LOAN_BALANCE',
  INTEREST_RATE: 'INTEREST_RATE',
  PAR_VARIABLE: 'PAR_VARIABLE',
  PAR_INTEREST: 'PAR_INTEREST',
  ESTIMATED_PRICE: 'ESTIMATED_PRICE',
  DEPOSIT: 'DEPOSIT',
  PURPOSE_TO_BUY: 'PURPOSE_TO_BUY',
  IS_FIRST_HOME: 'IS_FIRST_HOME',
  ESTIMATION: 'ESTIMATION',
  IS_ADVICE_APPLIED: 'IS_ADVICE_APPLIED',
  IS_EVALUATED: 'IS_EVALUATED',
  LOAN_SUMMARY: 'LOAN_SUMMARY',
  ADVICE: 'ADVICE',
  PREFERRED_RATE_TYPE: 'PREFERRED_RATE_TYPE',
  PREFERRED_FIXED_RATE_TERM: 'PREFERRED_FIXED_RATE_TERM',
  PREFERRED_REPAYMENT_TYPE: 'PREFERRED_REPAYMENT_TYPE',
  PREFERRED_LENDER_TYPE: 'PREFERRED_LENDER_TYPE',
  PREFERRED_BANK_TYPE: 'PREFERRED_BANK_TYPE',
  PREFERENCE_INTEREST_RATE: 'PREFERENCE_INTEREST_RATE',
  PREFERENCE_CASH_BACK: 'PREFERENCE_CASH_BACK',
  DEAL_FEATURES: 'DEAL_FEATURES',
  DEAL_ATTRIBUTES: 'DEAL_ATTRIBUTES',
  SELECTED_DEAL_FEATURES: 'SELECTED_DEAL_FEATURES',
  FOUND_DEALS: 'FOUND_DEALS',
  DEALS_TO_COMPARE: 'DEALS_TO_COMPARE',
  CHOSEN_DEAL: 'CHOSEN_DEAL',
  IS_CHOOSE_LATER: 'IS_CHOOSE_LATER',
  PERSONALISED_DEALS: 'PERSONALISED_DEALS',
  FIRST_NAME: 'FIRST_NAME',
  MIDDLE_NAME: 'MIDDLE_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  ADDRESS: 'ADDRESS',
  PROPERTY_ADDRESS: 'PROPERTY_ADDRESS',
  ESTIMATED_PROPERTY_ID: 'ESTIMATED_PROPERTY_ID',
  PROPERTY_ESTIMATION: 'PROPERTY_ESTIMATION',
  CUSTOM_PROPERTY_ESTIMATION: 'CUSTOM_PROPERTY_ESTIMATION',
  PROPERTY_STATE: 'PROPERTY_STATE',
  PROPERTY_TYPE: 'PROPERTY_TYPE',
  PROPERTY_ADDRESS_SIGNED_CONTRACT: 'PROPERTY_ADDRESS_SIGNED_CONTRACT',
  IS_AGREE: 'IS_AGREE',
  REGISTRATION_TOKEN: 'REGISTRATION_TOKEN',
  MOBILE: 'MOBILE',
  CODE: 'CODE',
  VERIFIED_MOBILE: 'VERIFIED_MOBILE',
  INVITATION: 'INVITATION',
  CUSTOMER_INVITE: 'CUSTOMER_INVITE',
  REMAINING_FIXED_TERM: 'REMAINING_FIXED_TERM',
  REMAINING_FIXED_TERM_UNIT: 'REMAINING_FIXED_TERM_UNIT',
  REMAINING_LOAN_TERM: 'REMAINING_LOAN_TERM',
  REMAINING_LOAN_TERM_UNIT: 'REMAINING_LOAN_TERM_UNIT',
  INVITE_WITH_USER_NEEDS: 'INVITE_WITH_USER_NEEDS',
  PROPERTY_PURPOSE: 'PROPERTY_PURPOSE',
  PROPERTY_RENTAL_INCOME: 'PROPERTY_RENTAL_INCOME',
  PROPERTY_RENTAL_INCOME_UNIT: 'PROPERTY_RENTAL_INCOME_UNIT',
  PROPERTY_OWNERSHIP: 'PROPERTY_OWNERSHIP',
  PROPERTY_OWNERSHIP_PERCENTAGE: 'PROPERTY_OWNERSHIP_PERCENTAGE'
};

const registrationsActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.REFERRED_UID:
      return { ...state, referredUID: payload };
    case actionTypes.REFERENCE:
      return { ...state, reference: payload };
    case actionTypes.FOR_USER:
      return { ...state, forUser: payload };
    case actionTypes.ID:
      return { ...state, id: payload };
    case actionTypes.JOURNEY_STAGE:
      return ['exploring', 'searching', 'found', 'urgent'].indexOf(payload) >= 0 ? { ...state, journeyStage: payload } : state;
    case actionTypes.IS_SIGNED_CONTRACT:
      return ['yes', 'no'].indexOf(payload) >= 0 ? { ...state, isSignedContract: payload } : state;
    case actionTypes.SETTLEMENT_DATE:
      return { ...state, settlementDate: payload };
    case actionTypes.IS_CONVEYANCER:
      return ['yes', 'no'].indexOf(payload) >= 0 ? { ...state, isConveyancer: payload } : state;
    case actionTypes.IS_PRE_APPROVAL:
      return ['yes', 'no'].indexOf(payload) >= 0 ? { ...state, isPreApproval: payload } : state;
    case actionTypes.NUMBER_OF_LOANS:
      return { ...state, numberOfLoans: (!isNaN(payload) && payload > 0 && payload <= MAX_SPLIT_LOANS ? payload : undefined) };
    case actionTypes.LEND_PROVIDER:
      return { ...state, lendProvider: payload };
    case actionTypes.AMOUNT_PRE_APPROVED:
      return { ...state, amountPreApproved: payload };
    case actionTypes.REMAINING_LOAN_BALANCE:
      let newRemainingLoanBalance = [...state.remainingLoanBalance];
      if (!isNaN(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newRemainingLoanBalance[payload.index] = payload.value;
        return { ...state, remainingLoanBalance: newRemainingLoanBalance };
      } else {
        return state;
      }
    case actionTypes.INTEREST_RATE:
      let newInterestRate = [...state.interestRate];
      if (!isNaN(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newInterestRate[payload.index] = payload.value;
        return { ...state, interestRate: newInterestRate };
      } else {
        return state;
      }
    case actionTypes.PAR_VARIABLE:
      let newParVariable = [...state.parVariable];
      if (['variable', 'fixed'].includes(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newParVariable[payload.index] = payload.value;
        return { ...state, parVariable: newParVariable };
      } else {
        return state;
      }
    case actionTypes.PAR_INTEREST:
      let newParInterest = [...state.parInterest];
      if (['principal + interest', 'interest only'].includes(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newParInterest[payload.index] = payload.value;
        return { ...state, parInterest: newParInterest };
      } else {
        return state;
      }
    case actionTypes.ESTIMATED_PRICE:
      return { ...state, estimatedPrice: (!isNaN(payload) ? payload : undefined) };
    case actionTypes.DEPOSIT:
      return { ...state, deposit: (!isNaN(payload) ? payload : undefined) };
    case actionTypes.PURPOSE_TO_BUY:
      let newPurposeToBuy = [...state.purposeToBuy];
      if (['to live in', 'to invest'].includes(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newPurposeToBuy[payload.index] = payload.value;
        return { ...state, purposeToBuy: newPurposeToBuy };
      } else if (['to live in', 'to invest'].includes(payload.value)) {
        newPurposeToBuy = newPurposeToBuy.map((_) => payload.value);
        return { ...state, purposeToBuy: newPurposeToBuy };
      } else {
        return state;
      }
    case actionTypes.IS_FIRST_HOME:
      return ['yes', 'no'].indexOf(payload) >= 0 ? { ...state, isFirstHome: payload } : state;
    case actionTypes.ESTIMATION:
      return { ...state, estimation: payload };
    case actionTypes.IS_ADVICE_APPLIED:
      return { ...state, isAdviceApplied: !!payload };
    case actionTypes.IS_EVALUATED:
      return { ...state, isEvaluated: !!payload };
    case actionTypes.LOAN_SUMMARY:
      return { ...state, loanSummary: payload };
    case actionTypes.ADVICE:
      return { ...state, advice: payload };
    case actionTypes.PREFERRED_RATE_TYPE:
      return ['Variable', 'Fixed'].indexOf(payload) >= 0 ? { ...state, preferredRateType: payload } : state;
    case actionTypes.PREFERRED_FIXED_RATE_TERM:
      return { ...state, preferredFixedRateTerm: payload };
    case actionTypes.PREFERRED_REPAYMENT_TYPE:
      return ['Principal & Interest', 'Interest only'].indexOf(payload) >= 0 ? { ...state, preferredRepaymentType: payload } : state;
    case actionTypes.PREFERRED_LENDER_TYPE:
      return ['All', 'Bank', 'Non-bank'].indexOf(payload) >= 0 ? { ...state, preferredLenderType: payload } : state;
    case actionTypes.PREFERRED_BANK_TYPE:
      return ['All', 'Major'].indexOf(payload) >= 0 ? { ...state, preferredBankType: payload } : state;
    case actionTypes.PREFERENCE_INTEREST_RATE:
      return ['VeryImportant', 'NiceToHave', 'NotImportant'].indexOf(payload) >= 0 ? { ...state, preferenceInterestRate: payload } : state;
    case actionTypes.PREFERENCE_CASH_BACK:
      return ['VeryImportant', 'NiceToHave', 'NotImportant'].indexOf(payload) >= 0 ? { ...state, preferenceCashBack: payload } : state;
    case actionTypes.DEAL_FEATURES:
      return { ...state, dealFeatures: payload };
    case actionTypes.SELECTED_DEAL_FEATURES:
      return { ...state, selectedDealFeatures: payload };
    case actionTypes.DEAL_ATTRIBUTES:
      return { ...state, dealAttributes: payload };
    case actionTypes.FOUND_DEALS:
      if (!payload) return { ...state, foundDeals: undefined };

      const currentFoundDeals = state.foundDeals ? Object.assign({}, state.foundDeals) : undefined;

      if (currentFoundDeals && currentFoundDeals.page + 1 === payload.page) {
        const elements = [...currentFoundDeals.elements, ...payload.elements];
        const newFoundDeals = Object.assign({}, payload);
        newFoundDeals.elements = elements;
        return { ...state, foundDeals: newFoundDeals };
      }

      return { ...state, foundDeals: payload };
    case actionTypes.DEALS_TO_COMPARE:
      return { ...state, dealsToCompare: payload };
    case actionTypes.CHOSEN_DEAL:
      return { ...state, chosenDeal: payload };
    case actionTypes.IS_CHOOSE_LATER:
      return { ...state, isChooseLater: payload };
    case actionTypes.PERSONALISED_DEALS:
      return { ...state, personalisedDeals: payload };
    case actionTypes.FIRST_NAME:
      return { ...state, firstName: payload };
    case actionTypes.MIDDLE_NAME:
      return { ...state, middleName: payload };
    case actionTypes.LAST_NAME:
      return { ...state, lastName: payload };
    case actionTypes.EMAIL:
      return { ...state, email: payload };
    case actionTypes.ADDRESS:
      return { ...state, address: payload };
    case actionTypes.PROPERTY_ADDRESS:
      return { ...state, propertyAddress: payload };
    case actionTypes.ESTIMATED_PROPERTY_ID:
      return { ...state, estimatedPropertyId: payload };
    case actionTypes.PROPERTY_ESTIMATION:
      return { ...state, propertyEstimation: payload };
    case actionTypes.PROPERTY_STATE:
      return { ...state, propertyState: payload };
    case actionTypes.PROPERTY_TYPE:
      return { ...state, propertyType: payload };
    /*case actionTypes.CUSTOM_PROPERTY_ESTIMATION:
      return { ...state, customPropertyEstimation: payload };*/
    case actionTypes.PROPERTY_ADDRESS_SIGNED_CONTRACT:
      return { ...state, propertyAddressSignedContract: payload };
    case actionTypes.IS_AGREE:
      return { ...state, isAgree: payload };
    case actionTypes.REGISTRATION_TOKEN:
      return { ...state, registrationToken: payload };
    case actionTypes.MOBILE:
      return { ...state, mobile: payload };
    case actionTypes.CODE:
      return { ...state, code: payload };
    case actionTypes.VERIFIED_MOBILE:
      return { ...state, verifiedMobile: payload };
    case actionTypes.INVITATION:
      return { ...state, invitation: payload };
    case actionTypes.CUSTOMER_INVITE:
      return { ...state, customerInvite: payload };
    case actionTypes.IS_REFINANCE:
      return { ...state, isRefinance: payload };
    case actionTypes.REMAINING_FIXED_TERM:
      let newRemainingFixedTerm = [...state.remainingFixedTerm];
      if (!isNaN(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newRemainingFixedTerm[payload.index] = payload.value;
        return { ...state, remainingFixedTerm: newRemainingFixedTerm };
      } else {
        return state;
      }
    case actionTypes.REMAINING_FIXED_TERM_UNIT:
      let newRemainingFixedTermUnit = [...state.remainingFixedTermUnit];
      if (['months', 'years'].includes(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newRemainingFixedTermUnit[payload.index] = payload.value;
        return { ...state, remainingFixedTermUnit: newRemainingFixedTermUnit };
      } else {
        return state;
      }
    case actionTypes.REMAINING_LOAN_TERM:
      let newRemainingLoanTerm = [...state.remainingLoanTerm];
      if (!isNaN(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newRemainingLoanTerm[payload.index] = payload.value;
        return { ...state, remainingLoanTerm: newRemainingLoanTerm };
      } else {
        return state;
      }
    case actionTypes.REMAINING_LOAN_TERM_UNIT:
      let newRemainingLoanTermUnit = [...state.remainingLoanTermUnit];
      if (['months', 'years'].includes(payload.value) && !isNaN(payload.index) && Number.isInteger(payload.index)) {
        newRemainingLoanTermUnit[payload.index] = payload.value;
        return { ...state, remainingLoanTermUnit: newRemainingLoanTermUnit };
      } else {
        return state;
      }
    case actionTypes.INVITE_WITH_USER_NEEDS:
      return { ...state, inviteWithUserNeeds: payload };
    case actionTypes.STATE:
      return payload;
    case actionTypes.STATE_RESET:
      return defaultState;
    case actionTypes.PROPERTY_PURPOSE:
      return { ...state, propertyPurpose: payload };
    case actionTypes.PROPERTY_RENTAL_INCOME:
      return { ...state, propertyRentalIncome: payload };
    case actionTypes.PROPERTY_RENTAL_INCOME_UNIT:
      return { ...state, propertyRentalIncomeUnit: payload };
    case actionTypes.PROPERTY_OWNERSHIP:
      return { ...state, propertyOwnership: payload };
    case actionTypes.PROPERTY_OWNERSHIP_PERCENTAGE:
      return { ...state, propertyOwnershipPercentage: payload };
    default:
      return state;
  }
};

export default registrationsActions;

// ACTIONS
export const setId = payload => ({ type: actionTypes.ID, payload });
export const setFoundDeals = payload => ({ type: actionTypes.FOUND_DEALS, payload });
export const setReferredUID = payload => ({ type: actionTypes.REFERRED_UID, payload });
export const setReference = payload => ({ type: actionTypes.REFERENCE, payload });
export const setForUser = payload => ({ type: actionTypes.FOR_USER, payload });
export const setJourneyStage = payload => ({ type: actionTypes.JOURNEY_STAGE, payload });
export const setIsSignedContract = payload => ({ type: actionTypes.IS_SIGNED_CONTRACT, payload });
export const setSettlementDate = payload => ({ type: actionTypes.SETTLEMENT_DATE, payload });
export const setIsConveyancer = payload => ({ type: actionTypes.IS_CONVEYANCER, payload });
export const setIsPreApproval = payload => ({ type: actionTypes.IS_PRE_APPROVAL, payload });
export const setNumberOfLoans = payload => ({ type: actionTypes.NUMBER_OF_LOANS, payload });
export const setLendProvider = payload => ({ type: actionTypes.LEND_PROVIDER, payload });
export const setAmountPreApproved = payload => ({ type: actionTypes.AMOUNT_PRE_APPROVED, payload });
export const setRemainingLoanBalance = payload => ({ type: actionTypes.REMAINING_LOAN_BALANCE, payload });
export const setInterestRate = payload => ({ type: actionTypes.INTEREST_RATE, payload });
export const setParVariable = payload => ({ type: actionTypes.PAR_VARIABLE, payload });
export const setParInterest = payload => ({ type: actionTypes.PAR_INTEREST, payload });
export const setEstimatedPrice = payload => ({ type: actionTypes.ESTIMATED_PRICE, payload });
export const setDeposit = payload => ({ type: actionTypes.DEPOSIT, payload });
export const setPurposeToBuy = payload => ({ type: actionTypes.PURPOSE_TO_BUY, payload });
export const setIsFirstHome = payload => ({ type: actionTypes.IS_FIRST_HOME, payload });
export const setEstimation = payload => ({ type: actionTypes.ESTIMATION, payload });
export const setIsAdviceApplied = payload => ({ type: actionTypes.IS_ADVICE_APPLIED, payload });
export const setIsEvaluated = payload => ({ type: actionTypes.IS_EVALUATED, payload });
export const setLoanSummary = payload => ({ type: actionTypes.LOAN_SUMMARY, payload });
export const setAdvice = payload => ({ type: actionTypes.ADVICE, payload });
export const setPreferredRateType = payload => ({ type: actionTypes.PREFERRED_RATE_TYPE, payload });
export const setPreferredFixedRateTerm = payload => ({ type: actionTypes.PREFERRED_FIXED_RATE_TERM, payload });
export const setPreferredRepaymentType = payload => ({ type: actionTypes.PREFERRED_REPAYMENT_TYPE, payload });
export const setPreferredLenderType = payload => ({ type: actionTypes.PREFERRED_LENDER_TYPE, payload });
export const setPreferredBankType = payload => ({ type: actionTypes.PREFERRED_BANK_TYPE, payload });
export const setPreferenceInterestRate = payload => ({ type: actionTypes.PREFERENCE_INTEREST_RATE, payload });
export const setPreferenceCashBack = payload => ({ type: actionTypes.PREFERENCE_CASH_BACK, payload });
export const setSelectedDealFeatures = payload => ({ type: actionTypes.SELECTED_DEAL_FEATURES, payload });
export const setDealAttributes = payload => ({ type: actionTypes.DEAL_ATTRIBUTES, payload });
export const setDealsToCompare = payload => ({ type: actionTypes.DEALS_TO_COMPARE, payload });
export const setChosenDeal = payload => ({ type: actionTypes.CHOSEN_DEAL, payload });
export const setIsChooseLater = payload => ({ type: actionTypes.IS_CHOOSE_LATER, payload });
export const setPersonalizedDeals = payload => ({ type: actionTypes.PERSONALISED_DEALS, payload });
export const setFirstName = payload => ({ type: actionTypes.FIRST_NAME, payload });
export const setMiddleName = payload => ({ type: actionTypes.MIDDLE_NAME, payload });
export const setLastName = payload => ({ type: actionTypes.LAST_NAME, payload });
export const setApplicantEmail = payload => ({ type: actionTypes.EMAIL, payload });
export const setApplicantAddress = payload => ({ type: actionTypes.ADDRESS, payload });
export const setPropertyAddress = payload => ({ type: actionTypes.PROPERTY_ADDRESS, payload });
export const setEstimatedPropertyId = payload => ({ type: actionTypes.ESTIMATED_PROPERTY_ID, payload });
export const setPropertyEstimation = payload => ({ type: actionTypes.PROPERTY_ESTIMATION, payload });
export const setPropertyState = payload => ({ type: actionTypes.PROPERTY_STATE, payload });
export const setPropertyType = payload => ({ type: actionTypes.PROPERTY_TYPE, payload });
//export const setCustomPropertyEstimation = payload => ({ type: actionTypes.CUSTOM_PROPERTY_ESTIMATION, payload });
export const setPropertyAddressSignedContract = payload => ({ type: actionTypes.PROPERTY_ADDRESS_SIGNED_CONTRACT, payload });
export const setIsAgree = payload => ({ type: actionTypes.IS_AGREE, payload });
export const setMobile = payload => ({ type: actionTypes.MOBILE, payload });
export const setCode = payload => ({ type: actionTypes.CODE, payload });
export const setVerifiedMobile = payload => ({ type: actionTypes.VERIFIED_MOBILE, payload });
export const setInvitation = payload => ({ type: actionTypes.INVITATION, payload });
export const setCustomerInvite = payload => ({ type: actionTypes.CUSTOMER_INVITE, payload });
export const setIsRefinance = payload => ({ type: actionTypes.IS_REFINANCE, payload });
export const setRemainingFixedTerm = payload => ({ type: actionTypes.REMAINING_FIXED_TERM, payload });
export const setRemainingFixedTermUnit = payload => ({ type: actionTypes.REMAINING_FIXED_TERM_UNIT, payload });
export const setRemainingLoanTerm = payload => ({ type: actionTypes.REMAINING_LOAN_TERM, payload });
export const setRemainingLoanTermUnit = payload => ({ type: actionTypes.REMAINING_LOAN_TERM_UNIT, payload });
export const setInviteWithUserNeeds = payload => ({ type: actionTypes.INVITE_WITH_USER_NEEDS, payload });
export const setPropertyPurpose = payload => ({ type: actionTypes.PROPERTY_PURPOSE, payload });
export const setPropertyRentalIncome = payload => ({ type: actionTypes.PROPERTY_RENTAL_INCOME, payload });
export const setPropertyRentalIncomeUnit = payload => ({ type: actionTypes.PROPERTY_RENTAL_INCOME_UNIT, payload });
export const setPropertyOwnership = payload => ({ type: actionTypes.PROPERTY_OWNERSHIP, payload });
export const setPropertyOwnershipPercentage = payload => ({ type: actionTypes.PROPERTY_OWNERSHIP_PERCENTAGE, payload });

export const setJourneyState = payload => ({ type: actionTypes.STATE, payload });
export const resetJourneyState = () => ({ type: actionTypes.STATE_RESET });

