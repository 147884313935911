import React from 'react';
import { AuthRoute } from '../auth/components';

import { PropertyReports, PropertyReport } from './pages';
import sagas from './sagas';
import {USER_ROLE} from "../../utils/authorisation";

const allowedRoles = [USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Specialist, USER_ROLE.Audit];

const propertyReportsModule = {
  routes: [
    <AuthRoute key="property-reports" exact path="/property-reports" component={PropertyReports} headerType={'private'} authorization={allowedRoles} />,
    <AuthRoute key="property-report" exact path="/property-report/:reportId" component={PropertyReport} headerType={'private'} authorization={allowedRoles} />,
  ],
  userMenuItems: [
    {
      name: 'Property reports',
      path: '/property-reports',
      allowedRoles,
      isForSpecialist: true,
    },
  ],
  sagas,
};

export default propertyReportsModule;
