import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../ConfirmDeletionModal/styles.module.scss';
import '../ConfirmDeletionModal/styles.module.scss';
import { withDimensions } from '../../../common/HOC';
import { isFunc } from '../../../../utils/functions';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import {bookMeeting, getAvailableTimeSlots} from '../../../user/sagas';
import { displayErrorMessage } from '../../../../utils/displayErrorMessage';
import { MONTHS, WEEK_DAYS } from '../../../../vars';
import history from '../../../../utils/history';
import { getTimeZone } from '../../../../utils/helpers';


const TIMEZONE = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
const TIMEZONE_OFFSET = `GMT${getTimeZone()}`;

const ScheduleMeetingModal = ({
                            open,
                            setOpen,
                            specialistUser,
                            yesLabel,
                            noLabel,
                            yesAction,
                            noAction,
                            eventType
}) => {
  const dispatch = useDispatch();
  const dimensions = useSelector(state => state.dimensions);
  const { user, selectedUser } = useSelector(state => state.user);

  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeUser, setActiveUser] = useState(undefined);

  useEffect(() => {
    setActiveUser(selectedUser ?? user);
  }, [user, selectedUser]); // eslint-disable-line

  useEffect(() => {
    if (eventType && !!specialistUser?.id && open) {
      setIsProcessing(true);
      dispatch(getAvailableTimeSlots({
        eventType,
        specialistId: specialistUser.id,
        onSuccess: (data) => {
          let rawDates = (data.availableSlots ?? []).map((dateString) => {
            const date = new Date(dateString);
            const weekDay = WEEK_DAYS[date.getDay()];
            const day = date.getDate();
            const month = MONTHS[date.getMonth()];
            const year = date.getFullYear();

            return {
              dateString: dateString,
              year,
              month,
              day,
              weekDay,
              hour: date.getHours(),
              minute: date.getMinutes(),
              dayString: `${weekDay}, ${day} ${month} ${year}`
            };
          });

          let groupedDates = [];
          rawDates.forEach((d) => {
            const dateIndex = groupedDates.findIndex((gd) => gd.dayString === d.dayString);
            const newTimeSlot = { buttonLabel: getFormattedTimeLabel(d.hour, d.minute), dateString: d.dateString };
            if (dateIndex < 0) {
              groupedDates.push({ dayString: d.dayString, timeSlots: [newTimeSlot] });
            } else {
              groupedDates[dateIndex].timeSlots.push(newTimeSlot);
            }
          });

          setDates(groupedDates);
          setSelectedDate(null);
          setSelectedTime(null);
          setIsProcessing(false);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to get available time slots');
          setIsProcessing(false);
        }
      }))
    } else if (!open) {
      setDates([]);
      setSelectedDate(null);
      setSelectedTime(null);
    }
  }, [eventType, specialistUser, open]); // eslint-disable-line

  const getFormattedTimeLabel = (hours, minutes) => {
    const postfix = (hours < 12 ? 'AM' : 'PM');
    const hourNumber = hours === 0 ? 12 : (hours <= 12 ? hours : hours - 12);
    return `${hourNumber < 10 ? `0${hourNumber}` : hourNumber}:${minutes < 10 ? `0${minutes}` : minutes} ${postfix}`;
  };

  const renderDateSelector = () => {
    return (<FormControl variant="outlined" className={styles.selectWrapper}>
      <Select value={selectedDate} displayEmpty style={{ backgroundColor: "white" }} disabled={isProcessing}>
        <MenuItem value={null} style={{ display: 'none' }}>
          <div style={{ textTransform: "none", color: '#8B90A0' }}>{'Choose preferred date'}</div>
        </MenuItem>
        {(dates ?? []).map((option, idx) => {
          return (
            <MenuItem value={option} key={idx} onClick={() => setSelectedDate(option)}>
              <div style={{ textTransform: "none" }}>{option?.dayString}</div>
            </MenuItem>
          )
        })}
      </Select>
      <Box className={styles.timezoneCaption}>
        Time zone: <span className={styles.value}>{TIMEZONE ?? ''} {TIMEZONE_OFFSET}</span>
      </Box>
    </FormControl>);
  };

  const renderAdditionalInfo = () => {
    return (<>
      <Box className={styles.info}><AccessTimeIcon  className={styles.icon}/> {specialistUser.domiumCalendar?.[eventType]?.duration} min</Box>
      <Box className={styles.info}>{eventType === 'call' ? (<>
        <PhoneIcon  className={styles.icon}/> Phone call
      </>) : (<>
        <VideoCallIcon  className={styles.icon}/> Meeting
      </>)}</Box>
      <Box className={styles.info}>{specialistUser.domiumCalendar?.[eventType]?.description}</Box>
    </>);
  };

  const renderTimeSlots = () => {
    return (<Grid item fullWidth>
      {!!selectedDate?.timeSlots?.length && (<>
        <Box sx={{ mb: 3 }} fullWidth className={styles.label}>Choose from available slots:</Box>
      </>)}
      <Grid container item direction={'row'} spacing={2} className={styles.timeSlotsWrapper} alignContent={'flex-start'} alignItems={'flex-start'}>
      {(!dates?.length && !isProcessing) && (<Grid item xs={12} className={styles.label}>There are no available slots during nearest days</Grid>)}
      {(!isProcessing && !!dates?.length && !!selectedDate && !selectedDate?.timeSlots?.length) && (<Grid item xs={12} className={styles.label}>
        There are no available slots on the selected date
      </Grid>)}
      {!!selectedDate?.timeSlots?.length && (<>
        {selectedDate.timeSlots.map((slot, idx) => (
          <Grid item key={idx} xs={6}>
            <Button
              variant={selectedTime?.dateString === slot.dateString ? 'contained' : 'outlined'}
              color={selectedTime?.dateString === slot.dateString ? 'primary' : 'secondary'}
              onClick={() => setSelectedTime(slot)}
              className={styles.smallBtn}
              fullWidth
              disabled={isProcessing}
            >
              {slot.buttonLabel}
            </Button>
          </Grid>
        ))}
      </>)}
    </Grid></Grid>);
  };

  const renderMobileContent = () => {
    return (<>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <Box className={styles.avatarWrapper}>
            {specialistUser?.photo ? (
              <img className={styles.avatar} src={specialistUser.photo} alt="specialist's avatar"/>
            ) : (
              <div className={styles.avatar}><PersonIcon style={{ fontSize: 48 }} /></div>
            )}
          </Box>
          <Box className={styles.specialistName}>{specialistUser.name} {specialistUser.surname}</Box>
        </Grid>
        <Grid
          item
          container
          className={styles.rightSection}
          justifyContent="flex-end"
          direction="column"
          alignContent="flex-end"
          alignItems="flex-end"
        >
          {renderAdditionalInfo()}
        </Grid>
      </Grid>
      <Box sx={{ mb: 3 }} />
      <Grid container justifyContent="space-between" spacing={4} direction={"column"}>
        <Grid item className={styles.scheduleMeetingDialogWrapper}>
          {renderDateSelector()}
        </Grid>
        {renderTimeSlots()}
      </Grid>
    </>);
  };


  return (
    <>
      <Dialog open={open} className={'ScheduleMeetingDialog'} onClose={() => { setSelectedTime(null); setSelectedDate(null); }} fullScreen={dimensions.isScreenLessSM}>
        <MuiDialogTitle disableTypography>
          <Box className={`${styles.modalTitle} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>Choose time</Box>
        </MuiDialogTitle>
        <MuiDialogContent>
          {(activeUser?.id && specialistUser?.id) && (<>
            {dimensions?.isScreenBiggerSM ? (
              <Grid container justifyContent="space-between" className={`${styles.scheduleMeetingDialogWrapper}`}>
                <Grid
                  item
                  className={styles.leftSection}
                  container
                  justifyContent="flex-start"
                  direction="column"
                  alignContent="center"
                  alignItems="center"
                >
                  <Box className={styles.avatarWrapper}>
                    {specialistUser.photo ? (
                      <img className={styles.avatar} src={specialistUser.photo} alt="specialist's avatar"/>
                    ) : (
                      <div className={styles.avatar}><PersonIcon style={{ fontSize: 96 }} /></div>
                    )}
                  </Box>
                  <Box className={styles.specialistName}>{specialistUser.name} {specialistUser.surname}</Box>
                  {renderAdditionalInfo()}
                </Grid>
                <Grid
                  item
                  className={styles.rightSection}
                  container
                  justifyContent="flex-start"
                  direction="column"
                  alignContent="flex-start"
                  alignItems="flex-start"
                  spacing={4}
                >
                  <Grid item className={styles.dateSelectorWrapper}>
                    {renderDateSelector()}
                  </Grid>
                  {renderTimeSlots()}
                </Grid>
              </Grid>
            ) : (
              <Box className={`${styles.scheduleMeetingDialogWrapper} ${styles.mobileContent}`}>
                {renderMobileContent()}
              </Box>
            )}
          </>)}
        </MuiDialogContent>
        <MuiDialogActions>
          <Grid container justifyContent="space-between">
            <Button
              className={`${styles.actionButton} ${styles.secondary}`}
              onClick={() => { if (isFunc(noAction)) noAction(); setOpen(false); }}
              color="secondary"
              variant="outlined"
              type="button"
              disableElevation
              disabled={!!isProcessing}
            >
              {noLabel ?? 'CANCEL'}
            </Button>
            <Button
              className={`${styles.actionButton}`}
              type="button"
              variant="contained"
              color="primary"
              disabled={!!isProcessing || !selectedTime}
              onClick={() => {
                localStorage.setItem('CALENDAR_REDIRECT_TIME', selectedTime.dateString);
                setIsProcessing(true);
                dispatch(bookMeeting({
                  userId: specialistUser?.id,
                  customerUserId: !!selectedUser ? (selectedUser?.id ?? selectedUser?._id) : undefined,
                  eventType,
                  startTime: selectedTime.dateString,
                  onSuccess: () => {
                    if (isFunc(yesAction)) yesAction(eventType, specialistUser?.id, selectedTime);
                    history.push('/scheduled');
                    setOpen(false);
                    setIsProcessing(false);
                  },
                  onError: (data) => {
                    displayErrorMessage(data, 'Unable to get available time slots');
                    setIsProcessing(false);
                  }
                }));
              }}
            >
              {yesLabel ?? 'COMPLETE'}
            </Button>
          </Grid>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

ScheduleMeetingModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  specialistUser: PropTypes.object,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  yesAction: PropTypes.func,
  noAction: PropTypes.func,
  closeOnSubmit: PropTypes.bool,
  eventType: PropTypes.string,
};

export default withDimensions(ScheduleMeetingModal);

