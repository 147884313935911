import { call, takeLatest, takeEvery, debounce } from 'redux-saga/effects';
import axios from 'axios';
import queryString from 'query-string';

import { isFunc } from '../../../utils/functions';

const Api = {
  getAccounts: ({ page, limit, sort }) => {
    let params = { limit: limit ?? 100 };
    if (page || page === 0) params.page = page;
    if (sort) params.sort = sort;

    return axios.request({
      url: '/accounts',
      method: 'get',
      params
    });
  },
  getAccount: (id) => axios.get(`/accounts/${id}`),
  createAccount: (body) => axios.post(`/accounts`, body),
  updateAccount: ({id, body}) => axios.put(`/accounts/${id}`, body),
  deleteAccount: (id) => axios.delete(`/accounts/${id}`),
};

export const actionTypes = {
  GET_ACCOUNTS: 'GET_ACCOUNTS',
  GET_ACCOUNT: 'GET_ACCOUNT',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
};

function* sagaGetAccounts({ payload: { limit, page, sort, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getAccounts, { limit, page, sort });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAccount({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAccount, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateAccount({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createAccount, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateAccount({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateAccount, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteAccount({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteAccount, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getAccounts = payload => ({ type: actionTypes.GET_ACCOUNTS, payload });
export const getAccount = payload => ({ type: actionTypes.GET_ACCOUNT, payload });
export const createAccount = payload => ({ type: actionTypes.CREATE_ACCOUNT, payload });
export const updateAccount = payload => ({ type: actionTypes.UPDATE_ACCOUNT, payload });
export const deleteAccount = payload => ({ type: actionTypes.DELETE_ACCOUNT, payload });

const accountsSagas = [
  takeLatest(actionTypes.GET_ACCOUNTS, sagaGetAccounts),
  takeEvery(actionTypes.GET_ACCOUNT, sagaGetAccount),
  takeEvery(actionTypes.CREATE_ACCOUNT, sagaCreateAccount),
  takeEvery(actionTypes.UPDATE_ACCOUNT, sagaUpdateAccount),
  takeEvery(actionTypes.DELETE_ACCOUNT, sagaDeleteAccount),
];

export default accountsSagas;