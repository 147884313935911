import {call, debounce, put, takeLatest, takeLeading, takeEvery, all} from 'redux-saga/effects';
import axios from 'axios';

import config from '../../../config';
import { actionTypes as userActionType } from '../reducers';
import { isFunc } from '../../../utils/functions';
import history from '../../../utils/history';

const Api = {
  user: (id) => axios.get(`/profile/me${id ? `?user=${id}` : ''}`),
  getAllowedUsers: ({ limit, page, search, sort, identity, status, specialist, referredBy, reference, createdLastDays }) => {
    let params = {
      limit: limit ?? 10
    };
    if (search) params.search = search;
    if (sort) params.sort = sort;
    if (identity) params.identity = identity;
    if (status) params.status = status;
    if (specialist) params.specialist = specialist;
    if (referredBy) params.referredBy = referredBy;
    if (reference) params.reference = reference;
    if (page || page === 0) params.page = page;
    if (createdLastDays) params.createdLastDays = createdLastDays;

    return axios.request({
      url: '/allowedUsers',
      method: 'get',
      params
    });
  },
  getSelectedUser: (id) => axios.get(`/allowedUser/${id}`),
  updateAllowedUser: ({id, body, runAutoVerification}) => axios.put(`/allowedUser/${id}${!!runAutoVerification ? `?runAutoVerification=true` : ''}`, body),
  clearAutoVerification: (id) => axios.put(`/allowedUser/${id}/clear-verification`),
  updateMe: ({id, body}) => axios.put(`/profile/me${id ? `?user=${id}` : ''}`, body),
  sendMobile: ({ mobile }) => axios.post(`/oauth/mobile`, { mobile }),
  login: ({ loginData }) =>
    axios.post(`/oauth/token`, { ...loginData, grant_type: 'password', scope: 'domium:write', client_id: config.CLIENT_ID }),
  deleteUser: (id) => axios.delete(`/users/${id}`),
  uploadPhoto: ({ formData, config, id }) => axios.post(`/profile/uploadPhoto${id ? `?user=${id}` : ''}`, formData, { ...config, headers: { 'Content-Type': 'multipart/form-data' } }),
  inviteCustomer: (body) => axios.post(`/profile/invite`, { ...body, inputURL: `${config.APP_SERVER_URL}/journey/new-invite?id=` }),
  getSpecialists: ({ page, limit, sort }) => {
    let params = {
      limit: limit ?? 100
    };
    if (page || page === 0) params.page = page;
    params.sort = sort ?? 'name';

    return axios.request({
      url: '/specialists',
      method: 'get',
      params
    });
  },
  getSpecialistsOnly: ({ page, limit, sort }) => {
    let params = {
      limit: limit ?? 100
    };
    if (page || page === 0) params.page = page;
    params.sort = sort ?? 'name';

    return axios.request({
      url: '/specialists-only',
      method: 'get',
      params
    });
  },
  getConveyancers: ({ page, limit, sort }) => {
    let params = {
      limit: limit ?? 100
    };
    if (page || page === 0) params.page = page;
    params.sort = sort ?? 'name';

    return axios.request({
      url: '/conveyancers',
      method: 'get',
      params
    });
  },
  getReferences: ({ page, limit, sort, search }) => {
    let params = {
      limit: limit ?? 100
    };
    if (page || page === 0) params.page = page;
    params.sort = sort ?? 'name';
    if (search) params.search = search;

    return axios.request({
      url: '/references',
      method: 'get',
      params
    });
  },
  getNextGenSSO: () => axios.get(`/services/nextgen/sso`),
  getAvailableTimeSlots: ({specialistId, eventType}) => axios.get(`/office365/${specialistId}/availableCalendarSlots/${eventType}`),
  bookMeeting: ({userId, customerUserId, eventType, startTime}) => axios.post(`/office365/${userId}/meeting`, { customerUserId, eventType, startTime }),
};

export const actionTypes = {
  GET_USER: 'GET_USER',
  GET_ALLOWED_USERS: 'GET_ALLOWED_USERS',
  GET_ALLOWED_USERS_WITH_DEBOUNCE: 'GET_ALLOWED_USERS_WITH_DEBOUNCE',
  GET_ALLOWED_USERS_BY_ID: 'GET_ALLOWED_USERS_BY_ID',
  SEND_MOBILE: 'SEND_MOBILE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  GET_SELECTED_USER: 'GET_SELECTED_USER',
  CLEAR_SELECTED_USER: 'CLEAR_SELECTED_USER',
  DELETE_USER: 'DELETE_USER',
  GET_ALLOWED_USER: 'GET_ALLOWED_USER',
  UPDATE_ALLOWED_USER: 'UPDATE_ALLOWED_USER',
  CLEAR_AUTO_VERIFICATION: 'CLEAR_AUTO_VERIFICATION',
  UPDATE_ME: 'UPDATE_ME',
  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  INVITE_CUSTOMER: 'INVITE_CUSTOMER',
  GET_SPECIALISTS: 'GET_SPECIALIST',
  GET_SPECIALISTS_ONLY: 'GET_SPECIALIST_ONLY',
  GET_CONVEYANCERS: 'GET_CONVEYANCERS',
  GET_REFERENCES: 'GET_REFERENCES',
  GET_NEXTGEN_SSO: 'GET_NEXTGEN_SSO',
  GET_AVAILABLE_TIME_SLOTS: 'GET_AVAILABLE_TIME_SLOTS',
  BOOK_MEETING: 'BOOK_MEETING'
};

const processLoginResult = async (result, onSuccess, onError) => {
  if (result?.data?.access_token) {
    await localStorage.setItem('X-ACCESS-TOKEN', result.data.access_token);
    if (result?.data?.refresh_token) await localStorage.setItem('X-REFRESH-TOKEN', result.data.refresh_token);
    sessionStorage.setItem('explicitlyLoggedIn', (Date.now()).toString());
    localStorage.setItem('shouldRedirectToDashboard', (Date.now()).toString());
    if (isFunc(onSuccess) && result.data) onSuccess(result.data);
  } else if (isFunc(onError)) onError(result?.data);
};

const clearSelectedUserFn = () => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete('selected_user');
  history.push({
    pathname: pathname,
    search: searchParams.toString()
  });
};

function* performLogOut(isSoft, onSuccess) {
  /*const path = window.location?.pathname;
  if (path && !['/login', '/login/phone', '/login/pin', '/incorrect-link'].includes(path.toString())) {
    localStorage.setItem('LOG-OUT-PATH', path.toString());
  }*/

  if (!isSoft) {
    yield put({ type: 'USER_LOGOUT' });
  } else {
    yield put({ type: userActionType.USER, payload: null });
  }
  localStorage.removeItem('REFRESH-TOKEN-EXPIRED-OR-INCORRECT');
  localStorage.removeItem('X-ACCESS-TOKEN');
  localStorage.removeItem('X-REFRESH-TOKEN');
  sessionStorage.setItem('explicitlyLoggedOut', (Date.now()).toString());
  localStorage.setItem('shouldLogOut', (Date.now()).toString());

  clearSelectedUserFn();
  if (!isSoft) {
    history.push('/');
  }
  yield put({ type: userActionType.IS_LOADED_USER, payload: true });
  if (isFunc(onSuccess)) onSuccess();
}

function* refreshOrLogOut({ payload }) {
  const refreshToken = yield localStorage.getItem('X-REFRESH-TOKEN');
  if (refreshToken) {
    const body = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      scope: 'domium:write',
      client_id: config.CLIENT_ID
    };

    const result = yield axios.post('oauth/token', body).then();

    if (!result || result.status >= 400) {
      localStorage.removeItem('X-ACCESS-TOKEN');
      localStorage.removeItem('X-REFRESH-TOKEN');
      yield put({ type: userActionType.IS_LOADED_USER, payload: true });
    } else if (result?.data?.access_token) {
      yield localStorage.setItem('X-ACCESS-TOKEN', result.data.access_token);
      if (result?.data?.refresh_token) yield localStorage.setItem('X-REFRESH-TOKEN', result.data.refresh_token);
      yield sagaGetUser({ payload: { ...(payload ?? {}), refreshing: true } });
    }
  } else {
    yield performLogOut();
  }
}

function* sagaSendMobile({ payload: { mobile, onSuccess, onError } }) {
  try {
    const result = yield call(Api.sendMobile, { mobile });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetUser({ payload }) {
  try {
    if (!localStorage.getItem('X-ACCESS-TOKEN') && !payload?.id) {
      if (!localStorage.getItem('X-REFRESH-TOKEN')) {
        const path = window.location?.pathname;
        const isLogin = !!path && ['/login', '/login/phone', '/login/pin'].includes(path);
        if (!isLogin) yield performLogOut();
        yield put({ type: userActionType.IS_LOADED_USER, payload: true });
        return;
      } else {
        yield refreshOrLogOut({ payload });
        return;
      }
    }

    const result = yield call(Api.user, payload?.id);
    if (!result) yield performLogOut();
    else if (result.status >= 400) yield refreshOrLogOut({ payload });
    else {
      if (!payload?.id) {
        yield put({ type: userActionType.USER, payload: result.data });
        yield put({ type: userActionType.IS_LOADED_USER, payload: true });
      }
      if (isFunc(payload?.onSuccess)) payload.onSuccess(result.data);
    }
  } catch {
    if (isFunc(payload?.onError)) payload.onError();
  }
}

function* sagaGetAllowedUsers({ payload: { limit, page, search, sort, identity, status, specialist, referredBy, reference, createdLastDays, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getAllowedUsers, { limit, page, search, sort, identity, status, specialist, referredBy, reference, createdLastDays });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAllowedUsersById({ payload: { ids, onSuccess, onError } }) {
  try {
    const requests = [];
    ids.forEach((id) => requests.push(call(Api.getSelectedUser, id)));
    const results = yield all(requests);
    if (results.every((result) => result.status < 400 || result.status === 404 || result.status === 403 || result.status === 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data?.id ? result.data : undefined));
    } else if (isFunc(onError)) onError();
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSelectedUser({ payload: { id, onSuccess, onError }}) {
  try {
    const result = yield call(Api.getSelectedUser, id);
    if (result.status < 400) {
      yield put({ type: userActionType.SELECTED_USER, payload: result.data });
      yield put({ type: userActionType.IS_LOADED_SELECTED_USER, payload: true });
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAllowedUser({ payload: { id, onSuccess, onError }}) {
  try {
    const result = yield call(Api.getSelectedUser, id); //"getSelectedUser" is correct
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetNextGenSSO({ payload: { onSuccess, onError }}) {
  try {
    const result = yield call(Api.getNextGenSSO);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAvailableTimeSlots({ payload: { specialistId, eventType, onSuccess, onError }}) {
  try {
    const result = yield call(Api.getAvailableTimeSlots, { specialistId, eventType });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBookMeeting({ payload: { userId, customerUserId, eventType, startTime, onSuccess, onError }}) {
  try {
    const result = yield call(Api.bookMeeting, { userId, customerUserId, eventType, startTime });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSpecialists({ payload: { limit, page, sort, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getSpecialists, { limit, page, sort });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSpecialistsOnly({ payload: { limit, page, sort, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getSpecialistsOnly, { limit, page, sort });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetConveyancers({ payload: { limit, page, sort, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getConveyancers, { limit, page, sort });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetReferences({ payload: { limit, page, sort, search, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getReferences, { limit, page, sort, search });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateAllowedUser({ payload: { id, body, runAutoVerification, shouldUpdateSelectedUser = false, onSuccess, onError }}) {
  try {
    const result = yield call(Api.updateAllowedUser, { id, body, runAutoVerification });
    if (result.status < 400) {
      if (result.data?.id && shouldUpdateSelectedUser) {
        yield put({ type: userActionType.SELECTED_USER, payload: result.data });
        yield put({ type: userActionType.IS_LOADED_SELECTED_USER, payload: true });
      }
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaClearAutoVerification({ payload: { id, onSuccess, onError }}) {
  try {
    const result = yield call(Api.clearAutoVerification, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateMe({ payload: { body, id, shouldUpdateSelectedUser = false, onSuccess, onError }}) {
  try {
    const result = yield call(Api.updateMe, {body, id});
    if (result.status < 400) {
      if (result.data?.id && !id) {
        yield put({ type: userActionType.USER, payload: result.data });
        yield put({ type: userActionType.IS_LOADED_USER, payload: true });
      }
      if (id && shouldUpdateSelectedUser) {
        yield put({ type: userActionType.SELECTED_USER, payload: result.data });
        yield put({ type: userActionType.IS_LOADED_SELECTED_USER, payload: true });
      }
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaLogin({ payload: { loginData, onSuccess, onError } }) {
  try {
    const result = yield call(Api.login, { loginData });
    yield processLoginResult(result, onSuccess, onError);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export function* sagaLogOut({ payload: { isSoft, onSuccess } }) {
  try {
    yield performLogOut(isSoft, onSuccess);
  } catch {
    console.log('Unable to perform logout');
  }
}

export function* sagaClearSelectedUser({ payload: { onSuccess, onError } }) {
  try {
    yield put({ type: userActionType.SELECTED_USER, payload: null });
    yield put({ type: userActionType.IS_LOADED_SELECTED_USER, payload: false });
    //yield localStorage.removeItem('X_SELECTED_USER');
    //yield put({ type: 'CLEAR_SELECTED_USER' });
    clearSelectedUserFn();
    if (isFunc(onSuccess)) onSuccess();
  } catch {
    if (isFunc(onError)) onError();
  }
}

export function* sagaDeleteUser({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteUser, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUploadPhoto({ payload: { imageFile, id, onSuccess, onError, onProgressEvent, shouldUpdateMain = false } }) {
  try {
    const config = {
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (isFunc(onProgressEvent)) onProgressEvent(percentCompleted);
      }
    };

    const formData = new FormData();
    formData.append('photo', imageFile);
    const result = yield call(Api.uploadPhoto, { formData, config, id });
    if (result.status < 400) {
      if (shouldUpdateMain && result.data?.id) {
        yield put({ type: userActionType.USER, payload: result.data });
        yield put({ type: userActionType.IS_LOADED_USER, payload: true });
      }
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaInviteCustomer({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.inviteCustomer, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const login = payload => ({ type: actionTypes.LOGIN, payload });
export const sendMobile = payload => ({ type: actionTypes.SEND_MOBILE, payload });
export const getUser = payload => ({ type: actionTypes.GET_USER, payload });
export const logOut = payload => ({ type: actionTypes.LOGOUT, payload });
export const getSelectedUser = payload => ({ type: actionTypes.GET_SELECTED_USER, payload });
export const getAllowedUser = payload => ({ type: actionTypes.GET_ALLOWED_USER, payload });
export const clearSelectedUser = payload => ({ type: actionTypes.CLEAR_SELECTED_USER, payload });
export const getAllowedUsers = payload => ({ type: actionTypes.GET_ALLOWED_USERS, payload });
export const getAllowedUsersById = payload => ({ type: actionTypes.GET_ALLOWED_USERS_BY_ID, payload });
export const getAllowedUsersWithDebounce = payload => ({ type: actionTypes.GET_ALLOWED_USERS_WITH_DEBOUNCE, payload });
export const deleteUser = payload => ({ type: actionTypes.DELETE_USER, payload });
export const updateAllowedUser = payload => ({ type: actionTypes.UPDATE_ALLOWED_USER, payload });
export const clearAutoVerification = payload => ({ type: actionTypes.CLEAR_AUTO_VERIFICATION, payload });
export const updateMe = payload => ({ type: actionTypes.UPDATE_ME, payload });
export const uploadPhoto = payload => ({ type: actionTypes.UPLOAD_PHOTO, payload });
export const inviteCustomer = payload => ({ type: actionTypes.INVITE_CUSTOMER, payload });
export const getSpecialists = payload => ({ type: actionTypes.GET_SPECIALISTS, payload });
export const getSpecialistsOnly = payload => ({ type: actionTypes.GET_SPECIALISTS_ONLY, payload });
export const getConveyancers = payload => ({ type: actionTypes.GET_CONVEYANCERS, payload });
export const getReferences = payload => ({ type: actionTypes.GET_REFERENCES, payload });
export const getNextGenSSOURL = payload => ({ type: actionTypes.GET_NEXTGEN_SSO, payload });
export const getAvailableTimeSlots = payload => ({ type: actionTypes.GET_AVAILABLE_TIME_SLOTS, payload });
export const bookMeeting = payload => ({ type: actionTypes.BOOK_MEETING, payload });

const userSagas = [
  takeLeading(actionTypes.SEND_MOBILE, sagaSendMobile),
  takeLatest(actionTypes.LOGIN, sagaLogin),
  takeLatest(actionTypes.GET_USER, sagaGetUser),
  takeLatest(actionTypes.LOGOUT, sagaLogOut),
  takeLatest(actionTypes.GET_SELECTED_USER, sagaGetSelectedUser),
  takeLatest(actionTypes.GET_ALLOWED_USER, sagaGetAllowedUser),
  takeLatest(actionTypes.CLEAR_SELECTED_USER, sagaClearSelectedUser),
  debounce(900, actionTypes.GET_ALLOWED_USERS_WITH_DEBOUNCE, sagaGetAllowedUsers),
  takeLatest(actionTypes.GET_ALLOWED_USERS, sagaGetAllowedUsers),
  takeLatest(actionTypes.GET_ALLOWED_USERS_BY_ID, sagaGetAllowedUsersById),
  takeEvery(actionTypes.DELETE_USER, sagaDeleteUser),
  takeEvery(actionTypes.UPDATE_ALLOWED_USER, sagaUpdateAllowedUser),
  takeEvery(actionTypes.CLEAR_AUTO_VERIFICATION, sagaClearAutoVerification),
  takeEvery(actionTypes.UPDATE_ME, sagaUpdateMe),
  takeLatest(actionTypes.UPLOAD_PHOTO, sagaUploadPhoto),
  takeEvery(actionTypes.INVITE_CUSTOMER, sagaInviteCustomer),
  takeLatest(actionTypes.GET_SPECIALISTS, sagaGetSpecialists),
  takeLatest(actionTypes.GET_SPECIALISTS_ONLY, sagaGetSpecialistsOnly),
  takeLatest(actionTypes.GET_CONVEYANCERS, sagaGetConveyancers),
  takeLatest(actionTypes.GET_REFERENCES, sagaGetReferences),
  takeLatest(actionTypes.GET_NEXTGEN_SSO, sagaGetNextGenSSO),
  takeLatest(actionTypes.GET_AVAILABLE_TIME_SLOTS, sagaGetAvailableTimeSlots),
  takeEvery(actionTypes.BOOK_MEETING, sagaBookMeeting),
];

export default userSagas;
