import React, { useState } from 'react';
import { Button, Dialog, Grid, Box } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import './styles.global.scss';
import { withDimensions } from '../../../common/HOC';
import RadioButtonGroup from '../RadioButtonGroup';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";


const BookingMeetingModal = ({ open, setOpen, mate, scheduleMeeting }) => {
  const dimensions = useSelector(state => state.dimensions);

  const eventTypeOptions = [
    { value: 'call', label: `GIVE ME A CALL`, size: 200 },
    { value: 'meeting', label: 'ARRANGE A MEETING', size: 200 }
  ];

  const [eventType, setEventType] = useState(undefined);

  return (
    <>
      <Dialog open={open} className={'BookingMeetingDialog'} onClose={() => {}} fullScreen={dimensions.isScreenLessSM}>
        <MuiDialogTitle disableTypography>
          <Box className={`${styles.modalTitle} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>
            How can I help?
          </Box>
          <IconButton aria-label="close" className={styles.closeButton} onClick={() => setOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Grid container justifyContent="center">
            <Grid item className={styles.contentContainer}>
              <Grid
                container
                direction="column"
                spacing={4}
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  <Box className={styles.avatarWrapper}>
                    {mate?.photo ? (
                      <img
                        className={`${styles.avatar} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}
                        src={mate.photo}
                        alt="specialist's avatar"
                      />
                    ) : (
                      <div className={`${styles.avatar} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>
                        <PersonIcon style={{ fontSize: 104 }} />
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item className={styles.text}>
                  Hi, I am {mate?.name}, your Domium {mate?.role === 'Conveyancer' ? 'Conveyancer' : 'Home Loan Specialist'}. How would you like to discuss your needs?
                </Grid>
                <Grid item className={`${styles.info} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>
                  <RadioButtonGroup
                    onChange={(v) => setEventType(v)}
                    options={eventTypeOptions}
                    defaultValue={eventType}
                    minBtnWidth={180}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Grid container justifyContent="space-between">
            <Button
              className={`${styles.actionButton} ${styles.secondary}`}
              onClick={() => setOpen(false)}
              color="secondary"
              variant="outlined"
              type="button"
              disableElevation
            >
              CANCEL
            </Button>
            <Button
              className={`${styles.actionButton}`}
              type="button"
              variant="contained"
              color="primary"
              disabled={!eventType}
              onClick={() => { scheduleMeeting(eventType); }}
            >
              SCHEDULE
            </Button>
          </Grid>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

BookingMeetingModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  mate: PropTypes.object,
  scheduleMeeting: PropTypes.func.isRequired,
};

export default withDimensions(BookingMeetingModal);

